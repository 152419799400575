import { render, staticRenderFns } from "./currencymod.vue?vue&type=template&id=1732d689&"
import script from "./currencymod.vue?vue&type=script&lang=js&"
export * from "./currencymod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1732d689')) {
      api.createRecord('1732d689', component.options)
    } else {
      api.reload('1732d689', component.options)
    }
    module.hot.accept("./currencymod.vue?vue&type=template&id=1732d689&", function () {
      api.rerender('1732d689', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8Za7Y3g085kpJ/JS/comp/currencymod.vue"
export default component.exports